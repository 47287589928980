.main-about-section {
  padding: 320px 140px 210px;
  background: url('../../img/about-bg.png') top no-repeat;
  background-size: cover;

  @media (min-width: $screen-xxl) {
    padding: calc(320 / 1440 * 100vw) calc(140 / 1440 * 100vw) calc(210 / 1440 * 100vw);
  }

  @media (max-width: $screen-lg) {
    padding: 200px 40px 80px;
    margin-bottom: 120px;
  }

  @media (max-width: $screen-md) {
    padding: 440px 0 30px;
    margin-bottom: 100px;
    background-size: auto 530px;
  }

  .row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      flex-direction: column;
      align-items: center;
    }

    > div {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;

      &.headline-wrap {
        max-width: max-content;

        @media (max-width: $screen-md) {
          margin-bottom: 24px;
        }

        img {

          @media (min-width: $screen-xxl) {
            width: calc(320 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            max-width: 215px;
          }
        }
      }

      &.text-wrap {
        max-width: max-content;

        @media (max-width: $screen-md) {
          text-align: center;
        }

        p {
          font: 400 16px/1.6 $main-font;
          color: #D7D7D7;
          max-width: 330px;
          margin: 0 0 48px;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
            max-width: calc(330 / 1440 * 100vw);
            margin: 0 0 calc(48 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            max-width: 300px;
          }

          @media (max-width: $screen-md) {
            max-width: 340px;
            text-align: center;
            margin: 0 0 40px;
          }

          @media (max-width: $screen-sm) {
            max-width: 100%;
          }
        }

        a {
          @media (max-width: $screen-md) {
            font-size: 16px;
            height: 64px;
          }
        }
      }

      &.particles-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        @media (max-width: $screen-md) {
          display: none;
        }

        .item {
          position: absolute;

          &.item-left {
            bottom: 0;
            left: 0;
          }

          &.item-right {
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}
