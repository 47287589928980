.countdown-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  background: url('../../img/countdown-bg.png') top fixed no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: calc(130 / 1440 * 100vw) 0 calc(30 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    min-height: var(--app-height);
  }

  .headline-wrap {
    position: relative;
    z-index: 2;

    @media (max-width: $screen-xl) {
      padding-bottom: 100px;
    }

    h2 {
      font: 500 72px/.9 $main-font;
      color: $white-color;
      margin-bottom: 32px;
      letter-spacing: -5px;

      @media (min-width: $screen-xxl) {
        font-size: calc(72 / 1440 * 100vw);
        margin-bottom: calc(32 / 1440 * 100vw);
        letter-spacing: calc(-5 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        font-size: 48px;
        margin-bottom: 16px;
      }

      @media (max-width: $screen-md) {
        font-size: 32px;
        letter-spacing: -2px;
      }
    }

    p {
      font: 400 16px/1.6 $main-font;
      max-width: 500px;
      margin: 0 auto 48px;
      color: rgba($white-color, .8);

      @media (min-width: $screen-xxl) {
        font-size: calc(16 / 1440 * 100vw);
        margin: 0 auto calc(48 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        margin: 0 auto 40px;
      }

      @media (max-width: $screen-md) {
        font-size: 14px;
        max-width: 300px;
        margin: 0 auto 32px;
      }
    }
  }

  .images-wrap {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .item {
      position: absolute;
      bottom: 0;

      img {
        width: 100%;
      }

      &.item-1 {
        left: 60px;
        max-width: 380px;
        z-index: 1;

        @media (min-width: $screen-xxl) {
          left: calc(60 / 1440 * 100vw);
          max-width: calc(380 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          max-width: 300px;
        }

        @media (max-width: $screen-md) {
          max-width: 170px;
          left: 30px;
        }
      }

      &.item-2 {
        right: -100px;
        max-width: 550px;

        @media (min-width: $screen-xxl) {
          right: calc(-100 / 1440 * 100vw);
          max-width: calc(550 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          max-width: 450px;
        }

        @media (max-width: $screen-md) {
          max-width: 230px;
          right: 20px;
        }
      }
    }
  }
}
