.home-form-modal-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba($black-color, .8);
  backdrop-filter: blur(10px);
  transition: none;
  padding: 24px;

  @media (min-width: $screen-xxl) {
    backdrop-filter: blur(calc(10 / 1440 * 100vw));
  }

  @media (max-width: $screen-xl) {
    min-height: var(--app-height);
  }

  * {
    transition: none;
  }

  .modal-wrap {
    padding: 32px 40px;
    max-width: max-content;
    background: rgba(4, 8, 17, .64);
    border: 1px solid rgba($white-color, .2);
    backdrop-filter: blur(12px);
    border-radius: 16px;

    @media (min-width: $screen-xxl) {
      padding: calc(32 / 1440 * 100vw) calc(40 / 1440 * 100vw);
      backdrop-filter: blur(calc(12 / 1440 * 100vw));
      border-radius: calc(16 / 1440 * 100vw);
      border-width: calc(1 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      padding: 32px 16px;
    }

    .headline-wrap {
      position: relative;
      margin-bottom: 24px;

      @media (min-width: $screen-xxl) {
        margin-bottom: calc(24 / 1440 * 100vw);
      }

      h5 {
        font: 600 32px/1 $main-font;
        letter-spacing: -1.6px;
        color: $white-color;
        margin-bottom: 16px;

        @media (min-width: $screen-xxl) {
          font-size: calc(32 / 1440 * 100vw);
          margin-bottom: calc(16 / 1440 * 100vw);
          letter-spacing: calc(-1.6 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          max-width: 200px;
        }
      }

      p {
        font: 400 16px/1.6 $main-font;
        color: rgba($white-color, .8);
        letter-spacing: calc(-16px / 100 * 4);

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          letter-spacing: calc((-16 / 1440 / 100 * 4) * 100vw);
        }

        @media (max-width: $screen-md) {
          font-size: 14px;
        }
      }

      .close-wrap {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        img {
          width: 24px;

          @media (min-width: $screen-xxl) {
            width: calc(24 / 1440 * 100vw);
          }
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 25px;

      @media (min-width: $screen-xxl) {
        gap: calc(25 / 1440 * 100vw);
      }

      input {
        font: 400 14px/1 $main-font;
        height: 52px;
        width: 600px;
        box-shadow: none;
        border-radius: 12px;
        padding: 12px 16px;
        color: $white-color;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, .3);

        @media (min-width: $screen-xxl) {
          font-size: calc(14 / 1440 * 100vw);
          height: calc(52 / 1440 * 100vw);
          width: calc(600 / 1440 * 100vw);
          border-radius: calc(12 / 1440 * 100vw);
          padding: calc(12 / 1440 * 100vw) calc(16 / 1440 * 100vw);
          border-width: calc(1 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          width: auto;
        }

        &::placeholder {
          font: 400 14px/1 $main-font;
          color: #EFEBFF;

          @media (min-width: $screen-xxl) {
            font-size: calc(14 / 1440 * 100vw);
          }
        }

        &:focus {
          border-color: #3677EE;
        }
      }

      .btn-main {
        font-size: 16px;
        height: 60px;
        width: 100%;
        max-width: 100%;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          height: calc(60 / 1440 * 100vw);
        }
      }
    }
  }
}
