.home-news-section {
  margin: 0 0 120px;

  @media (min-width: $screen-xxl) {
    margin: 0 0 calc(120 / 1440 * 100vw);
  }

  .col-12 {
    padding: 0;

    @media (max-width: $screen-xl) {
      padding: 0 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 0 10px;
    }

    @media (max-width: $screen-md) {
      padding: 0 25px;
    }
  }

  .title-wrap {
    margin-bottom: 40px;

    @media (min-width: $screen-xxl) {
      margin-bottom: calc(40 / 1440 * 100vw);
    }

    h3 {
      font: 500 48px/.9 $main-font;
      color: $white-color;
      letter-spacing: -3px;

      @media (min-width: $screen-xxl) {
        font-size: calc(48 / 1440 * 100vw);
        letter-spacing: calc(-3 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        font-size: 32px;
        text-align: center;
        letter-spacing: -2px;
      }
    }
  }

  .swiper-wrap {
    width: 100%;
    margin: 0 auto;

    .swiper {
      position: relative;

      @media (max-width: $screen-md) {
        overflow: initial;
      }

      .swiper-button-prev, .swiper-button-next {
        height: 56px;
        width: 56px;
        border-radius: 100%;
        border: 1px solid rgba($white-color, .4);

        @media (min-width: $screen-xxl) {
          height: calc(56 / 1440 * 100vw);
          width: calc(56 / 1440 * 100vw);
          border-width: calc(1 / 1440 * 100vw);
        }

        &:hover {
          background-color: rgba($white-color, .2);
        }

        &::after {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: $white-color;
          width: 24px;
          height: 24px;

          @media (min-width: $screen-xxl) {
            font-size: calc(18 / 1440 * 100vw);
            width: calc(24 / 1440 * 100vw);
            height: calc(24 / 1440 * 100vw);
          }
        }

        &.swiper-button-disabled {
          opacity: 0;
        }
      }

      .swiper-button-prev {
        left: -36px;

        @media (min-width: $screen-xxl) {
          left: calc(-36 / 1440 * 100vw);
        }
      }

      .swiper-button-next {
        right: -36px;

        @media (min-width: $screen-xxl) {
          right: calc(-36 / 1440 * 100vw);
        }
      }
    }

    .swiper-slide {
      position: relative;

      &:not(.swiper-slide-active) {

        @media (max-width: $screen-md) {
          opacity: .4;
        }
      }

      .img-wrap {
        border-radius: 8px;
        overflow: hidden;
        margin: 0 0 16px;

        img {
          width: 100%;
        }
      }

      .content-wrap {
        position: relative;
        z-index: 2;
        padding: 0 12px;

        .headline-wrap {
          display: flex;
          align-items: center;
          gap: 16px;
          margin: 0 0 16px;

          h6 {
            font: 400 12px/20px $main-font;
            padding: 4px 16px;
            color: $white-color;
            border-radius: 100px;
            border: 1px solid #828282;

            @media (min-width: $screen-xxl) {
              font-size: calc(12 / 1440 * 100vw);
              padding: calc(4 / 1440 * 100vw) calc(16 / 1440 * 100vw);
              border-radius: calc(100 / 1440 * 100vw);
              border-width: calc(1 / 1440 * 100vw);
            }
          }

          p {
            font: 400 12px/1.6 $main-font;
            color: $white-color;
            letter-spacing: -0.24px;

            @media (min-width: $screen-xxl) {
              font-size: calc(12 / 1440 * 100vw);
              letter-spacing: calc(-0.24 / 1440 * 100vw);
            }
          }
        }

        .body-wrap {

          h5 {
            font: 500 24px/1.3 $main-font;
            color: $white-color;
            letter-spacing: -1.44px;
            margin: 0 0 8px;

            @media (min-width: $screen-xxl) {
              font-size: calc(24 / 1440 * 100vw);
              letter-spacing: calc(-1.44 / 1440 * 100vw);
              margin: 0 0 calc(8 / 1440 * 100vw);
            }
          }

          p {
            font: 400 16px/1.6 $main-font;
            color: rgba($white-color, .7);
            letter-spacing: -0.64px;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
              letter-spacing: calc(-0.64 / 1440 * 100vw);
            }
          }

          h6 {
            font: 400 16px/1.6 $main-font;
            color: $white-color;
            letter-spacing: -0.64px;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
              letter-spacing: calc(-0.64 / 1440 * 100vw);
            }

            span {
              background: linear-gradient(30deg, #8E54E9 -0.03%, #4776E6 113.32%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
  }
}
