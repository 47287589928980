.main-hero-section {
  padding: 330px 40px 60px;
  background: url('../../img/hero-bg.png') top no-repeat;
  background-size: cover;

  @media (min-width: $screen-xxl) {
    padding: calc(330 / 1440 * 100vw) calc(40 / 1440 * 100vw) calc(60 / 1440 * 100vw);
  }

  @media (max-width: $screen-lg) {
    padding: 640px 25px 100px 20px;
    background-position: 10%;
  }

  @media (max-width: $screen-md) {
    padding: 290px 0 35px;
    background-position: top left;
    background-size: 135%;
  }

  .row {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $screen-md) {
      justify-content: center;
    }

    > div {
      padding: 0;
      margin: 0;

      &.text-wrap {
        max-width: max-content;
        position: relative;
        z-index: 2;

        @media (max-width: $screen-md) {
          text-align: center;
        }

        .headline-wrap {
          margin: 0 0 40px;

          @media (min-width: $screen-xxl) {
            margin: 0 0 calc(40 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            margin: 0 0 20px;
          }

          img {

            @media (min-width: $screen-xxl) {
              width: calc(675 / 1440 * 100vw);
            }

            @media (max-width: $screen-lg) {
              max-width: 480px;
            }

            @media (max-width: $screen-md) {
              max-width: 340px;
            }
          }
        }

        .cta-wrap {
          display: flex;
          align-items: center;
          gap: 40px;

          @media (min-width: $screen-xxl) {
            gap: calc(40 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            flex-direction: column-reverse;
          }

          a {

            @media (max-width: $screen-md) {
              font-size: 16px;
              height: 64px;
              min-width: 210px;
            }
          }

          p {
            font: 400 16px/1.6 $main-font;
            color: #D7D7D7;
            max-width: 370px;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
              max-width: calc(370 / 1440 * 100vw);
            }

            @media (min-width: $screen-xxl) {
              max-width: calc(370 / 1440 * 100vw);
            }
          }
        }
      }

      &.particles-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        @media (max-width: $screen-md) {
          display: none;
        }

        .item {
          position: absolute;

          &.item-left {
            bottom: 0;
            left: 0;

            img {

              @media (min-width: $screen-xxl) {
                width: calc(427 / 1440 * 100vw);
              }
            }
          }

          &.item-right {
            right: 0;
            bottom: 0;

            @media (max-width: $screen-lg) {
              top: 40px;
              bottom: auto;
            }

            img {

              @media (min-width: $screen-xxl) {
                width: calc(339 / 1440 * 100vw);
              }
            }
          }
        }
      }

      &.scroll-wrap {
        position: absolute;
        z-index: 1;
        bottom: 48px;
        left: 64px;
        max-width: max-content;

        @media (min-width: $screen-xxl) {
          bottom: calc(48 / 1440 * 100vw);
          left: calc(64 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          left: 40px;
          bottom: 110px;
        }

        @media (max-width: $screen-md) {
          display: none;
        }

        img {

          @media (min-width: $screen-xxl) {
            width: calc(80 / 1440 * 100vw);
          }

          -webkit-animation: scrollRotating 15s linear infinite;
          -moz-animation: scrollRotating 15s linear infinite;
          -ms-animation: scrollRotating 15s linear infinite;
          -o-animation: scrollRotating 15s linear infinite;
          animation: scrollRotating 15s linear infinite;
        }
      }
    }
  }
}
