.home-features-section {
  margin: 0 0 40px;

  @media (min-width: $screen-xxl) {
    margin: 0 0 calc(40 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    margin: 0 0 100px;
  }

  @media (max-width: $screen-md) {
    margin: 0 0 80px;
  }

  .col-12 {
    padding: 0;

    @media (max-width: $screen-xl) {
      padding: 0 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 0 10px;
    }
  }

  .headline-wrap {
    margin: 0 0 32px;

    @media (min-width: $screen-xxl) {
      margin: 0 0 calc(32 / 1440 * 100vw);
    }

    h3 {
      font: 500 48px/.9 $main-font;
      color: $white-color;
      margin-bottom: 32px;
      letter-spacing: -3px;

      @media (min-width: $screen-xxl) {
        font-size: calc(48 / 1440 * 100vw);
        margin-bottom: calc(32 / 1440 * 100vw);
        letter-spacing: calc(-3 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        font-size: 32px;
        letter-spacing: -2px;
        text-align: center;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (min-width: $screen-xxl) {
      gap: calc(20 / 1440 * 100vw);
    }

    .item {
      width: calc((100% - 60px) / 4);
      border-radius: 16px;
      min-height: 360px;
      padding: 160px 24px 24px;
      position: relative;
      overflow: hidden;

      @media (min-width: $screen-xxl) {
        width: calc((100% - (60 / 1440 * 100vw)) / 4);
        border-radius: calc(16 / 1440 * 100vw);
        min-height: calc(360 / 1440 * 100vw);
        padding: calc(160 / 1440 * 100vw) calc(24 / 1440 * 100vw) calc(24 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        width: calc((100% - 20px) / 2);
        padding: 160px 40px 50px;
      }

      @media (max-width: $screen-md) {
        width: 100%;
        height: 220px;
        min-height: auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      h5 {
        font: 500 32px/1 $main-font;
        color: $white-color;
        margin-bottom: 8px;
        letter-spacing: -2px;
        position: relative;
        z-index: 2;

        @media (min-width: $screen-xxl) {
          font-size: calc(32 / 1440 * 100vw);
          margin-bottom: calc(8 / 1440 * 100vw);
          letter-spacing: calc(-2 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          font-size: 24px;
          letter-spacing: -1.44px;
        }

        br {
          @media (max-width: $screen-md) {
            display: none;
          }
        }
      }

      p {
        font: 400 16px/1.6 $main-font;
        color: rgba($white-color, .8);
        letter-spacing: -0.64px;
        position: relative;
        z-index: 2;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          letter-spacing: calc(-0.64 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          max-width: 230px;
        }

        @media (max-width: $screen-md) {
          font-size: 14px;
          max-width: 100%;
          letter-spacing: -0.56px;
        }
      }
    }
  }
}
