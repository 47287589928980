.main-roadmap-section {
  padding: 80px 140px 160px;

  @media (min-width: $screen-xxl) {
    padding: calc(80 / 1440 * 100vw) calc(140 / 1440 * 100vw) calc(160 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    padding: 0 40px;
    margin-bottom: 130px;
  }

  @media (max-width: $screen-md) {
    padding: 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;

      &.headline-wrap {
        text-align: center;
        margin-bottom: 56px;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(56 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          margin-bottom: 48px;
        }

        h2 {
          font: 500 88px/.9 $main-font;
          letter-spacing: -9px;
          color: $white-color;

          @media (min-width: $screen-xxl) {
            font-size: calc(88 / 1440 * 100vw);
            letter-spacing: calc(-9 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            font-size: 72px;
            letter-spacing: -6px;
          }

          @media (max-width: $screen-md) {
            font-size: 48px;
            letter-spacing: -3px;
          }
        }
      }

      &.items-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 52px;

        @media (min-width: $screen-xxl) {
          gap: calc(20 / 1440 * 100vw);
          margin-bottom: calc(52 / 1440 * 100vw);
        }

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(52 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          flex-direction: column;
          margin-bottom: 48px;
          gap: 16px;
        }

        .item {
          width: calc((100% - 40px) / 3);
          padding: 64px 32px 40px;
          border-radius: 16px;
          background-color: rgba(#050810, .64);
          border: 1px solid rgba($white-color, .2);
          position: relative;

          @media (min-width: $screen-xxl) {
            width: calc((100% - (40 / 1440 * 100vw)) / 3);
            border: calc(1 / 1440 * 100vw) solid rgba($white-color, .2);
            padding: calc(64 / 1440 * 100vw) calc(32 / 1440 * 100vw) calc(40 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            width: 100%;
            min-height: 356px;
            padding: 56px 24px 36px;
          }

          h5 {
            font: 600 32px/40px $main-font;
            margin-bottom: 16px;
            color: $white-color;

            @media (min-width: $screen-xxl) {
              font-size: calc(32 / 1440 * 100vw);
              line-height: calc(40 / 1440 * 100vw);
              margin-bottom: calc(16 / 1440 * 100vw);
            }

            &.text-gradient {
              margin-bottom: 8px;
              background: linear-gradient(27deg, #9750F1 0%, #3677EE 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              @media (min-width: $screen-xxl) {
                margin-bottom: calc(8 / 1440 * 100vw);
              }
            }
          }

          ul {
            margin: 0;
            padding-left: 18px;

            @media (min-width: $screen-xxl) {
              padding-left: calc(18 / 1440 * 100vw);
            }

            li {
              font: 400 16px/22px $main-font;
              color: #BDBDBD;

              @media (min-width: $screen-xxl) {
                font-size: calc(18 / 1440 * 100vw);
                line-height: calc(22 / 1440 * 100vw);
              }
            }
          }

          .arrow {
            position: absolute;
            right: -37px;
            top: 115px;
            z-index: 2;
            max-width: max-content;
            max-height: max-content;

            @media (min-width: $screen-xxl) {
              right: calc(-37 / 1440 * 100vw);
              top: calc(115 / 1440 * 100vw);
            }

            @media (max-width: $screen-md) {
              transform: rotate(90deg);
              bottom: -35px;
              right: auto;
              left: 24px;
              top: auto;
            }

            img {

              @media (min-width: $screen-xxl) {
                width: calc(55 / 1440 * 100vw);
              }
            }
          }
        }
      }

      &.cta-wrap {
        text-align: center;

        a {
          font-size: 16px;
          height: 68px;
          min-width: 220px;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
            height: calc(68 / 1440 * 100vw);
            min-width: calc(220 / 1440 * 100vw);
          }
        }
      }
    }
  }
}
