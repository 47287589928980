.main-membership-section {
  padding: 0 140px 80px;
  background: url('../../img/membership-bg.svg') center no-repeat;
  background-size: contain;

  @media (min-width: $screen-xxl) {
    padding: 0 calc(140 / 1440 * 100vw) calc(80 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    padding: 0 40px;
    margin-bottom: 130px;
  }

  @media (max-width: $screen-md) {
    padding: 0;
  }

  @media (max-width: $screen-sm) {
    background-size: 200%;
  }

  .row {

    > div {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;

      &.headline-wrap {
        max-width: max-content;
        margin: 0 auto 30px;

        @media (min-width: $screen-xxl) {
          margin: 0 auto calc(30 / 1440 * 100vw);
        }

        img {

          @media (min-width: $screen-xxl) {
            width: calc(434 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            max-width: 370px;
          }

          @media (max-width: $screen-sm) {
            max-width: 280px;
          }
        }
      }

      &.swiper-wrap {

        .swiper {
          overflow: initial;
        }

        .swiper-slide {
          height: auto;
          padding: 24px;
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          background-color: rgba(#050810, .64);
          border: 1px solid rgba($white-color, .2);

          @media (min-width: $screen-xxl) {
            padding: calc(24 / 1440 * 100vw);
            border-radius: calc(16 / 1440 * 100vw);
            border: calc(1 / 1440 * 100vw) solid rgba($white-color, .2);
          }

          .head-wrap {
            min-height: 100px;
            margin: 0 0 32px;
            text-align: center;
            color: $white-color;

            @media (min-width: $screen-xxl) {
              min-height: calc(100 / 1440 * 100vw);
              margin: 0 0 calc(32 / 1440 * 100vw);
            }

            p {
              font: 400 16px/22px $main-font;
              margin-bottom: 8px;

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
                line-height: calc(22 / 1440 * 100vw);
                margin-bottom: calc(8 / 1440 * 100vw);
              }
            }

            h5 {
              font: 600 32px/1 $main-font;

              @media (min-width: $screen-xxl) {
                font-size: calc(32 / 1440 * 100vw);
              }
            }
          }

          .body-wrap {
            margin-bottom: 36px;

            @media (min-width: $screen-xxl) {
              margin-bottom: calc(36 / 1440 * 100vw);
            }

            ul {
              list-style-type: none;
              padding: 0;

              li {
                font: 400 16px/24px $main-font;
                color: $white-color;
                display: flex;
                margin: 0 0 16px;

                @media (min-width: $screen-xxl) {
                  font-size: calc(16 / 1440 * 100vw);
                  line-height: calc(24 / 1440 * 100vw);
                  margin: 0 0 calc(16 / 1440 * 100vw);
                }

                &::before {
                  content: '';
                  background-image: url('../../img/ul-li-icon.svg');
                  background-size: 100%;
                  display: block;
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                  margin-right: 8px;

                  @media (min-width: $screen-xxl) {
                    width: calc(24 / 1440 * 100vw);
                    min-width: calc(24 / 1440 * 100vw);
                    height: calc(24 / 1440 * 100vw);
                    margin-right: calc(8 / 1440 * 100vw);
                  }
                }
              }
            }
          }

          .footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: auto;

            .price {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 32px;

              @media (min-width: $screen-xxl) {
                margin-bottom: calc(32 / 1440 * 100vw);
              }

              h5 {
                font: 600 32px/1 $main-font;
                margin-right: 5px;
                color: $white-color;

                @media (min-width: $screen-xxl) {
                  font-size: calc(32 / 1440 * 100vw);
                  margin-right: calc(5 / 1440 * 100vw);
                }
              }

              p {
                font: 400 14px/1 $main-font;
                color: #D7D7D7;

                @media (min-width: $screen-xxl) {
                  font-size: calc(14 / 1440 * 100vw);
                }
              }
            }

            a {
              font-size: 16px;

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
              }
            }
          }
        }
      }
    }
  }
}
