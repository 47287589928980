.dapp-hero-section {
  padding: 130px 0 30px;
  min-height: 100vh;
  background: url('../../img/dapp-hero-bg.png') center fixed no-repeat;
  background-size: cover;

  @media (min-width: $screen-xxl) {
    padding: calc(130 / 1440 * 100vw) 0 calc(30 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    min-height: var(--app-height);
  }

  @media (max-width: $screen-lg) {
    background: url('../../img/dapp-hero-tablet-bg.png') top fixed no-repeat;
    background-size: 100%;
  }

  @media (max-width: $screen-md) {
    background: transparent;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .headline-wrap {
      text-align: center;
      margin: 0 auto 24px;

      @media (min-width: $screen-xxl) {
        margin: 0 auto calc(24 / 1440 * 100vw);
      }

      img {
        width: 300px;

        @media (min-width: $screen-xxl) {
          width: calc(300 / 1440 * 100vw);
        }

        @media (max-width: $screen-sm) {
          width: 180px;
        }
      }
    }

    .nums-wrap {
      width: 100%;
      max-width: 680px;
      margin: 0 auto 16px;
      padding: 0;
      gap: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @media (min-width: $screen-xxl) {
        max-width: calc(680 / 1440 * 100vw);
        margin: 0 auto calc(16 / 1440 * 100vw);
        gap: calc(16 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        width: 100%;
      }

      @media (max-width: $screen-md) {
        gap: 8px;
        grid-template-columns: 1fr 1fr;
      }

      .num-item {
        border-radius: 16px;
        border: 1px solid rgba($white-color, .2);
        background-color: rgba(#050810, .32);
        padding: 24px;

        @media (min-width: $screen-xxl) {
          border-radius: calc(16 / 1440 * 100vw);
          border: calc(1 / 1440 * 100vw) solid rgba($white-color, .2);
          padding: calc(24 / 1440 * 100vw);
        }

        &:last-child {
          @media (max-width: $screen-md) {
            grid-column: 1/3;
          }
        }

        h4 {
          font: 600 32px/1 $main-font;
          margin: 0 0 8px;
          background: -webkit-linear-gradient(0deg, #8E54E9, #4776E6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @media (min-width: $screen-xxl) {
            font-size: calc(32 / 1440 * 100vw);
            margin: 0 0 calc(8 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            font-size: 24px;
          }
        }

        h5 {
          font: 500 16px/1.2 $main-font;
          color: rgba($white-color, .7);

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            font-size: 14px;
          }
        }
      }
    }

    .reward-wrap {
      width: 100%;
      max-width: 680px;
      padding: 32px 40px;
      border-radius: 16px;
      border: 1px solid rgba($white-color, .2);
      background-color: rgba(#050810, .32);

      @media (min-width: $screen-xxl) {
        max-width: calc(680 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw) calc(40 / 1440 * 100vw);
        border-radius: calc(16 / 1440 * 100vw);
        border: calc(1 / 1440 * 100vw) solid rgba($white-color, .2);
      }

      @media (max-width: $screen-xl) {
        margin: 0;
        width: 100%;
      }

      @media (max-width: $screen-md) {
        padding: 24px 16px;
      }

      .text-wrap {
        margin: 0 0 24px;

        @media (min-width: $screen-xxl) {
          margin: 0 0 calc(24 / 1440 * 100vw);
        }

        h4 {
          font: 600 32px/1 $main-font;
          margin: 0 0 4px;
          color: $white-color;

          @media (min-width: $screen-xxl) {
            font-size: calc(32 / 1440 * 100vw);
            margin: 0 0 calc(4 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            font-size: 24px;
          }
        }

        h5 {
          font: 500 16px/1.5 $main-font;
          color: rgba($white-color, .7);

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            font-size: 14px;
          }
        }
      }

      .input-wrap {
        position: relative;
        margin: 0 0 24px;

        @media (min-width: $screen-xxl) {
          margin: 0 0 calc(24 / 1440 * 100vw);
        }

        input {
          font: 400 14px/1.2 $main-font;
          color: $white-color;
          width: 100%;
          height: 52px;
          padding: 12px 16px;
          border: 1px solid rgba($white-color, .32);
          background-color: transparent;
          box-shadow: none;
          outline: none;
          border-radius: 12px;

          @media (min-width: $screen-xxl) {
            font-size: calc(14 / 1440 * 100vw);
            height: calc(52 / 1440 * 100vw);
            padding: calc(12 / 1440 * 100vw) calc(12 / 1440 * 100vw);
            border: calc(1 / 1440 * 100vw) solid rgba($white-color, .32);
            border-radius: calc(12 / 1440 * 100vw);
          }

          &::placeholder {
            font: 400 14px/1.2 $main-font;
            color: #EFEBFF;

            @media (min-width: $screen-xxl) {
              font-size: calc(14 / 1440 * 100vw);
            }
          }
        }

        a {
          font: 600 14px/1.2 $main-font;
          color: $white-color;
          position: absolute;
          top: 8px;
          right: 8px;
          height: 36px;
          border-radius: 8px;
          border: 0;
          padding: 9px;

          @media (min-width: $screen-xxl) {
            font-size: calc(14 / 1440 * 100vw);
            top: calc(8 / 1440 * 100vw);
            right: calc(8 / 1440 * 100vw);
            height: calc(36 / 1440 * 100vw);
            border-radius: calc(8 / 1440 * 100vw);
            padding: calc(9 / 1440 * 100vw);
          }

          .bg {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 6px;

            @media (min-width: $screen-xxl) {
              border-radius: calc(6 / 1440 * 100vw);
            }
          }
        }
      }

      .actions-wrap {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr;

        @media (min-width: $screen-xxl) {
          gap: calc(16 / 1440 * 100vw);
        }

        @media (max-width: $screen-sm) {
          grid-template-columns: 1fr;
        }

        a {
          font: 600 18px/1.2 $main-font;
          width: 100%;
          height: 70px;
          max-width: 100%;

          @media (min-width: $screen-xxl) {
            font-size: calc(18 / 1440 * 100vw);
            height: calc(70 / 1440 * 100vw);
          }

          @media (max-width: $screen-sm) {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}
