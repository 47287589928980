.home-feed-section {
  margin: 0 0 100px;

  @media (min-width: $screen-xxl) {
    margin: 0 0 calc(100 / 1440 * 100vw);
  }

  .col-12 {
    padding: 0;

    @media (max-width: $screen-xl) {
      padding: 0 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 0 10px;
    }

    @media (max-width: $screen-md) {
      padding: 0 40px;
    }
  }

  .headline-wrap {
    margin: 0 0 48px;

    @media (min-width: $screen-xxl) {
      margin: 0 0 calc(48 / 1440 * 100vw);
    }

    h3 {
      font: 500 48px/.9 $main-font;
      color: $white-color;
      margin-bottom: 32px;
      letter-spacing: -3px;

      @media (min-width: $screen-xxl) {
        font-size: calc(48 / 1440 * 100vw);
        margin-bottom: calc(32 / 1440 * 100vw);
        letter-spacing: calc(-3 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        font-size: 32px;
        text-align: center;
      }
    }
  }

  .swiper {
    position: relative;

    @media (max-width: $screen-md) {
      overflow: initial;
    }

    .swiper-button-prev, .swiper-button-next {
      height: 56px;
      width: 56px;
      border-radius: 100%;
      border: 1px solid rgba($white-color, .4);

      @media (min-width: $screen-xxl) {
        height: calc(56 / 1440 * 100vw);
        width: calc(56 / 1440 * 100vw);
        border-width: calc(1 / 1440 * 100vw);
      }

      &:hover {
        background-color: rgba($white-color, .2);
      }

      &::after {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: $white-color;
        width: 24px;
        height: 24px;

        @media (min-width: $screen-xxl) {
          font-size: calc(18 / 1440 * 100vw);
          width: calc(24 / 1440 * 100vw);
          height: calc(24 / 1440 * 100vw);
        }
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .swiper-button-prev {
      left: -36px;

      @media (min-width: $screen-xxl) {
        left: calc(-36 / 1440 * 100vw);
      }
    }

    .swiper-button-next {
      right: -36px;

      @media (min-width: $screen-xxl) {
        right: calc(-36 / 1440 * 100vw);
      }
    }
  }

  .swiper-slide {
    position: relative;

    &:not(.swiper-slide-active) {

      @media (max-width: $screen-md) {
        opacity: .4;
      }
    }

    img {
      width: 100%;
    }
  }
}
