.home-hero-section {
  margin: 100px 0 120px;

  @media (min-width: $screen-xxl) {
    margin: calc(100 / 1440 * 100vw) 0 calc(120 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    margin: 148px 0 96px;
  }

  @media (max-width: $screen-lg) {
    margin: 96px 0 160px;
  }

  .col-12 {
    padding: 0;

    @media (max-width: $screen-xl) {
      padding: 0 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 0 10px;
    }
  }

  .swiper-wrap {
    width: 100%;
    margin: 0 auto;

    .swiper {
      overflow: initial;
      position: relative;

      .swiper-pagination {
        display: flex;
        align-items: center;
        position: absolute;
        left: 120px;
        bottom: 110px;
        gap: 8px;

        @media (min-width: $screen-xxl) {
          left: calc(120 / 1440 * 100vw);
          bottom: calc(110 / 1440 * 100vw);
          gap: calc(8 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          left: 48px;
          bottom: 64px;
        }

        @media (max-width: $screen-md) {
          bottom: -48px;
          left: 0;
          right: 0;
          margin: 0 auto;
          justify-content: center;
        }

        .swiper-pagination-bullet {
          height: 4px;
          width: 75px;
          display: block;
          z-index: 4;
          opacity: .4;
          background-color: #D9D9D9;

          @media (min-width: $screen-xxl) {
            height: calc(4 / 1440 * 100vw);
            width: calc(75 / 1440 * 100vw);
          }

          &.swiper-pagination-bullet-active {
            opacity: 1;
            background-color: $white-color;
          }
        }
      }

      .swiper-button-prev, .swiper-button-next {
        height: 56px;
        width: 56px;
        border-radius: 100%;
        border: 1px solid rgba($white-color, .4);

        @media (min-width: $screen-xxl) {
          height: calc(56 / 1440 * 100vw);
          width: calc(56 / 1440 * 100vw);
          border-width: calc(1 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          display: none;
        }

        &:hover {
          background-color: rgba($white-color, .2);
        }

        &::after {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: $white-color;
          width: 24px;
          height: 24px;

          @media (min-width: $screen-xxl) {
            font-size: calc(18 / 1440 * 100vw);
            width: calc(24 / 1440 * 100vw);
            height: calc(24 / 1440 * 100vw);
          }
        }

        &.swiper-button-disabled {
          opacity: 0;
        }
      }

      .swiper-button-prev {
        left: -36px;

        @media (min-width: $screen-xxl) {
          left: calc(-36 / 1440 * 100vw);
        }
      }

      .swiper-button-next {
        right: -36px;

        @media (min-width: $screen-xxl) {
          right: calc(-36 / 1440 * 100vw);
        }
      }
    }

    .swiper-slide {
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      min-height: 645px;
      padding: 120px;

      @media (min-width: $screen-xxl) {
        border-radius: calc(16 / 1440 * 100vw);
        min-height: calc(645 / 1440 * 100vw);
        padding: calc(120 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        padding: 72px 48px 180px;
        min-height: auto;
        height: auto;
      }

      @media (max-width: $screen-md) {
        padding: 32px 24px 220px;
      }

      &:not(.swiper-slide-active) {
        opacity: .4;
      }

      .bg-wrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (max-width: $screen-md) {
          height: 50%;
          top: auto;
          bottom: 0;
        }

        &::before {
          content: '';
          position: absolute;
          height: 140%;
          width: 100%;
          top: -100%;
          left: 0;
          display: none;
          background: linear-gradient(180deg, #130E21 70%, transparent 100%);

          @media (max-width: $screen-md) {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;

          @media (max-width: $screen-md) {
            height: 130%;
            object-position: top right;
          }
        }
      }

      .content-wrap {
        position: relative;
        z-index: 2;

        @media (max-width: $screen-md) {
          text-align: center;
        }

        h2 {
          font: 500 72px/.9 $main-font;
          color: $white-color;
          margin-bottom: 24px;
          letter-spacing: -5px;

          @media (min-width: $screen-xxl) {
            font-size: calc(72 / 1440 * 100vw);
            margin-bottom: calc(24 / 1440 * 100vw);
            letter-spacing: calc(-5 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            letter-spacing: -2px;
          }

          @media (max-width: $screen-md) {
            font-size: 32px;
            margin-bottom: 16px;
          }
        }

        p {
          font: 400 16px/1.6 $main-font;
          max-width: 380px;
          margin: 0 0 40px;
          color: rgba($white-color, .8);
          letter-spacing: -0.64px;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
            max-width: calc(380 / 1440 * 100vw);
            margin: 0 0 calc(40 / 1440 * 100vw);
            letter-spacing: calc(-0.64 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            font-size: 14px;
            margin: 0 auto 24px;
          }
        }

        .buttons-wrap {
          display: flex;
          gap: 8px;

          @media (min-width: $screen-xxl) {
            gap: calc(8 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            justify-content: center;
          }

          .btn-main {
            font-size: 16px;
            height: 60px;
            padding: 24px 48px;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
              height: calc(60 / 1440 * 100vw);
              padding: calc(24 / 1440 * 100vw) calc(48 / 1440 * 100vw);
            }

            @media (max-width: $screen-md) {
              height: 50px;
              font-size: 14px;
              padding: 20px 24px;
            }
          }
        }
      }
    }
  }
}
