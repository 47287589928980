.main-token-section {
  padding: 48px 140px 80px;
  background: url('../../img/token-bg.svg') center no-repeat;
  background-size: cover;

  @media (min-width: $screen-xxl) {
    padding: calc(48 / 1440 * 100vw) calc(140 / 1440 * 100vw) calc(80 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    padding: 0 40px;
    margin-bottom: 100px;
  }

  @media (max-width: $screen-md) {
    padding: 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    > div {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;

      &.text-wrap {
        max-width: max-content;
        padding-right: 135px;

        @media (min-width: $screen-xxl) {
          padding-right: calc(135 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          padding-right: 50px;
        }

        @media (max-width: $screen-md) {
          padding-right: 0;
          text-align: center;
          margin: 0 auto 24px;
        }

        > img {
          max-width: 140px;
          margin-left: -24px;
          margin-bottom: 16px;

          @media (min-width: $screen-xxl) {
            width: calc(140 / 1440 * 100vw);
            max-width: calc(140 / 1440 * 100vw);
            margin-left: calc(-24 / 1440 * 100vw);
            margin-bottom: calc(16 / 1440 * 100vw);
          }
        }

        .headline-wrap {
          margin-bottom: 35px;

          @media (min-width: $screen-xxl) {
            margin-bottom: calc(35 / 1440 * 100vw);
          }

          img {

            @media (min-width: $screen-xxl) {
              width: calc(457 / 1440 * 100vw);
            }

            @media (max-width: $screen-xl) {
              max-width: 390px;
            }

            @media (max-width: $screen-md) {
              max-width: 290px;
            }
          }
        }

        p {
          font: 400 16px/1.2 $main-font;
          color: #BDBDBD;
          margin-bottom: 56px;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
            margin-bottom: calc(56 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            margin-bottom: 40px;
          }
        }

        a {
          font-size: 16px;
          height: 68px;
          min-width: 220px;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
            height: calc(68 / 1440 * 100vw);
            min-width: calc(220 / 1440 * 100vw);
          }
        }
      }

      &.accordion-wrap {
        flex: 1;

        .accordion-item {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          border-bottom: 1px solid #2D2D2D;

          @media (min-width: $screen-xxl) {
            border-bottom: calc(1 / 1440 * 100vw) solid #2D2D2D;
          }

          .accordion-header {

            .accordion-button {
              background-color: transparent;
              border-radius: 0;
              border: 0;
              padding: 32px 14px;
              color: $white-color;
              box-shadow: none;

              @media (min-width: $screen-xxl) {
                padding: calc(32 / 1440 * 100vw) calc(14 / 1440 * 100vw);
              }

              @media (max-width: $screen-md) {
                padding: 32px 0;
              }

              &::after {
                background-image: url('../../img/accordion-closed-icon.svg');

                @media (min-width: $screen-xxl) {
                  width: calc(24 / 1440 * 100vw);
                  height: calc(24 / 1440 * 100vw);
                  background-size: cover;
                }
              }

              &:not(.collapsed)::after {
                background-image: url('../../img/accordion-opened-icon.svg');

                @media (min-width: $screen-xxl) {
                  width: calc(24 / 1440 * 100vw);
                  height: calc(24 / 1440 * 100vw);
                  background-size: cover;
                }
              }

              h5 {
                font: 600 32px/1 $main-font;
                color: $white-color;

                @media (min-width: $screen-xxl) {
                  font-size: calc(32 / 1440 * 100vw);
                }

                @media (max-width: $screen-md) {
                  font-size: 24px;
                }
              }
            }
          }

          .accordion-body {
            padding: 0 14px 32px;

            @media (min-width: $screen-xxl) {
              padding: 0 calc(14 / 1440 * 100vw) calc(32 / 1440 * 100vw);
            }

            @media (max-width: $screen-md) {
              padding: 0 0 32px;
            }

            h6 {
              font: 400 16px/24px $main-font;
              color: $white-color;
              margin-bottom: 8px;

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
                line-height: calc(24 / 1440 * 100vw);
                margin-bottom: calc(8 / 1440 * 100vw);
              }
            }

            p {
              font: 400 16px/22px $main-font;
              color: #BDBDBD;

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
                line-height: calc(22 / 1440 * 100vw);
              }
            }
          }
        }
      }
    }
  }
}
