.main-modes-section {
  padding: 80px 140px 100px;
  background: url('../../img/modes-bg.svg') center no-repeat;
  background-size: cover;

  @media (min-width: $screen-xxl) {
    padding: calc(80 / 1440 * 100vw) calc(140 / 1440 * 100vw) calc(100 / 1440 * 100vw);
  }

  @media (max-width: $screen-xl) {
    padding: 80px 40px 60px 20px;
    margin-bottom: 120px;
  }

  @media (max-width: $screen-lg) {
    padding: 0 0 0 40px;
  }

  @media (max-width: $screen-md) {
    padding: 0;
  }

  .row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      align-items: center;
      flex-direction: column-reverse;
    }

    > div {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 1;

      &.content-wrap {
        max-width: 360px;

        @media (min-width: $screen-xxl) {
          max-width: calc(360 / 1440 * 100vw);
        }

        @media (max-width: $screen-sm) {
          max-width: 100%;
        }

        .headline-wrap {
          max-width: max-content;
          margin: 0 0 40px;

          @media (min-width: $screen-xxl) {
            margin: 0 0 calc(40 / 1440 * 100vw);
          }

          @media (min-width: $screen-xxl) {
            margin: 0 0 calc(40 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            margin: 0 auto 24px;
          }

          img {

            @media (min-width: $screen-xxl) {
              width: calc(322 / 1440 * 100vw);
            }

            @media (max-width: $screen-md) {
              max-width: 200px;
            }
          }
        }

        .tabs-wrap {

          .nav-tabs {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 0;
          }

          .nav-item {
            width: 50%;

            button {
              font: 600 16px/1.2 $main-font;
              color: rgba($white-color, .6);
              width: 100%;
              border: 0;
              height: 64px;
              padding: 16px;
              background-color: transparent;
              position: relative;
              border-bottom: 2px solid rgba($white-color, .24);

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
                height: calc(64 / 1440 * 100vw);
                padding: calc(16 / 1440 * 100vw);
                border-bottom: calc(2 / 1440 * 100vw) solid rgba($white-color, .24);
              }

              &[aria-selected="true"] {
                color: $white-color;
                border-bottom: 2px solid #8E54E9;

                @media (min-width: $screen-xxl) {
                  border-bottom: calc(2 / 1440 * 100vw) solid #8E54E9;
                }
              }
            }
          }

          .text-wrap {
            font: 400 16px/1.6 $main-font;
            padding: 40px 0 0;
            color: #D7D7D7;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
              padding: calc(40 / 1440 * 100vw) 0 0;
            }

            p {
              margin: 0 0 16px;

              @media (min-width: $screen-xxl) {
                margin: 0 0 calc(16 / 1440 * 100vw);
              }
            }
          }
        }
      }

      &.img-wrap {
        flex: 1;

        img {
          width: 100%;

          @media (max-width: $screen-lg) {
            max-width: 150%;
          }

          @media (max-width: $screen-md) {
            max-width: 100%;
          }
        }
      }
    }
  }
}
