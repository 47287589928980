// Variables
@import "../vars/breakpoints";
@import "../vars/colors";
@import "../vars/transitions";
@import "../vars/typography";
@import "../vars/buttons";

html body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: $black-color;

  &.hidden {
    overflow: hidden;
  }
}

* {
  transition: $fast-transition;
}

#root {
  @media (min-width: $screen-xxl) {
    //transform: scale(var(--app-width-scale));
    //transform-origin: top;
  }
}

body .container {

  @media (min-width: $screen-xxl) {
    max-width: calc(1164 / 1440 * 100vw);
    padding: 0;
  }

  @media (max-width: $screen-xxl) {
    max-width: 1164px;
    padding: 0;
  }

  @media (max-width: $screen-md) {
    padding: 0 15px;
  }

  .row {
    @media (max-width: $screen-xxl) {
      margin: 0;
    }
  }
}

[class^="col-"] {
  padding: 0 8px;
}

main {
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: $screen-xxl) {
    max-width: 100vw;
  }

  section {
    position: relative;
    z-index: 3;
    width: 100%;
  }
}

.display-none {
  display: none !important;
}

.mobile-only {
  display: none !important;

  @media (max-width: $screen-lg) {
    display: flex !important;
  }
}

@-webkit-keyframes scrollRotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scrollRotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #050810;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;

  @media (max-width: $screen-xl) {
    height: var(--app-height);
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 2px;
    border: 2px solid #2F3FE8;
    border-radius: 50%;
    animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2F3FE8 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

[data-aos] {
  pointer-events: auto !important;
  transform: none !important;
}

html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
  opacity: 1 !important;
}

html:not(.no-js) [data-aos=fade-up] {
  transform: none !important;
}
