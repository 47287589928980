footer {
  border-top: 1px solid rgba($white-color, .4);
  position: relative;

  @media (min-width: $screen-xxl) {
    border-top: calc(1 / 1440 * 100vw) solid rgba($white-color, .4);
  }

  .container {
    padding: 48px 0;

    @media (min-width: $screen-xxl) {
      padding: calc(48 / 1440 * 100vw) 0;
    }

    @media (max-width: $screen-xl) {
      padding: 48px 40px 54px;
    }

    @media (max-width: $screen-lg) {
      padding: 30px 15px 95px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-lg) {
      flex-direction: column;
    }

    > div {
      padding: 0;
      margin: 0;
      max-width: max-content;

      &.logo-wrap {
        display: flex;
        flex-direction: column;
        margin-right: auto;

        img {
          max-width: 56px;
          margin-bottom: 30px;

          @media (min-width: $screen-xxl) {
            width: calc(56 / 1440 * 100vw);
            max-width: calc(56 / 1440 * 100vw);
            margin-bottom: calc(30 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            margin-bottom: 40px;
          }
        }

        .copyright {
          font: 400 16px/1.2 $main-font;
          color: $white-color;
          margin-top: auto;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            position: absolute;
            bottom: 35px;
            left: 15px;
          }
        }
      }

      &.nav-wrap {
        display: flex;

        @media (max-width: $screen-lg) {
          margin-bottom: 40px;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0 130px 0 0;

          @media (min-width: $screen-xxl) {
            margin: 0 calc(130 / 1440 * 100vw) 0 0;
          }

          @media (max-width: $screen-lg) {
            margin: 0 45px 0 0;
          }

          &:last-child {
            @media (max-width: $screen-lg) {
              margin: 0;
            }
          }

          li {
            margin: 0;

            &:not(:last-child) {
              margin-bottom: 24px;

              @media (min-width: $screen-xxl) {
                margin-bottom: calc(24 / 1440 * 100vw);
              }
            }

            a {
              font: 400 16px/1.2 $main-font;
              color: #D7D7D7;
              text-decoration: none;

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
              }
            }
          }
        }
      }

      &.socials-wrap {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: $screen-lg) {
          width: 100%;
          max-width: 100%;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
        }

        p {
          font: 400 16px/1.2 $main-font;
          color: $white-color;

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          list-style-type: none;
          padding: 0;
          margin: 30px 0 0;
          gap: 11px;
          max-width: 150px;

          @media (min-width: $screen-xxl) {
            gap: calc(11 / 1440 * 100vw);
            margin: calc(30 / 1440 * 100vw) 0 0;
            max-width: calc(150 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            justify-content: flex-start;
            max-width: 100%;
            margin-top: 16px;
          }

          li {
            margin: 0;

            a {
              font: 400 16px/1.2 $main-font;
              color: #D7D7D7;
              text-decoration: none;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 40px;
              border-radius: 40px;
              background: linear-gradient(27deg, #9750F1 0%, #3677EE 100%);

              @media (min-width: $screen-xxl) {
                font-size: calc(16 / 1440 * 100vw);
                height: calc(40 / 1440 * 100vw);
                width: calc(40 / 1440 * 100vw);
                border-radius: calc(40 / 1440 * 100vw);
              }

              img {
                width: 100%;
                max-width: 24px;

                @media (min-width: $screen-xxl) {
                  max-width: calc(24 / 1440 * 100vw);
                }
              }
            }
          }
        }
      }
    }
  }

  //.container-fluid {
  //
  //  .bottom-wrap {
  //    width: 100%;
  //    max-width: 100%;
  //    gap: 8px;
  //    display: flex;
  //    flex-wrap: wrap;
  //    align-items: center;
  //    justify-content: center;
  //    padding: 30px;
  //    background-color: #212121;
  //    border-top: 1px solid #3F3F3F;
  //
  //    @media (min-width: $screen-xxl) {
  //      gap: calc(8 / 1440 * 100vw);
  //      padding: calc(30 / 1440 * 100vw);
  //      border-bottom: calc(1 / 1440 * 100vw) solid #3F3F3F;
  //    }
  //
  //    @media (max-width: $screen-lg) {
  //      gap: 4px;
  //      flex-direction: column;
  //    }
  //
  //    p {
  //      font: 600 16px/1.5 $main-font;
  //      color: rgba($white-color, .7);
  //
  //      @media (min-width: $screen-xxl) {
  //        font-size: calc(16 / 1440 * 100vw);
  //      }
  //
  //      @media (max-width: $screen-lg) {
  //        font-size: 12px;
  //      }
  //
  //      &.num {
  //        color: $white-color;
  //      }
  //    }
  //  }
  //}
}
