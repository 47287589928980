.home-join-section {
  margin: 0 0 110px;

  @media (min-width: $screen-xxl) {
    margin: 0 0 calc(110 / 1440 * 100vw);
  }

  @media (max-width: $screen-md) {
    margin: 0 0 80px;
  }

  .col-12 {
    padding: 0;

    @media (max-width: $screen-xl) {
      padding: 0 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 0 10px;
    }
  }

  .content-wrap {
    position: relative;
    padding-top: 80px;
    overflow: hidden;
    border-radius: 16px;

    @media (min-width: $screen-xxl) {
      padding-top: calc(80 / 1440 * 100vw);
      border-radius: calc(16 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      padding-top: 0;
    }

    .bg {
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      height: calc(100% - 80px);
      border-radius: 16px;
      overflow: hidden;

      @media (min-width: $screen-xxl) {
        top: calc(80 / 1440 * 100vw);
        height: calc(100% - 80 / 1440 * 100vw);
        border-radius: calc(16 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        height: 50%;
        top: auto;
        bottom: 0;
        overflow: initial;
      }

      &::before {
        content: '';
        position: absolute;
        height: 140%;
        width: 100%;
        top: -100%;
        left: 0;
        display: none;
        background: linear-gradient(180deg, #130E21 70%, transparent 100%);

        @media (max-width: $screen-md) {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;

        @media (max-width: $screen-md) {
          object-position: top right;
        }
      }
    }

    .text-wrap {
      position: relative;
      z-index: 2;
      padding: 48px;

      @media (min-width: $screen-xxl) {
        padding: calc(48 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        text-align: center;
        padding: 32px 16px 410px;
      }

      h3 {
        font: 500 48px/1 $main-font;
        color: $white-color;
        margin-bottom: 24px;
        letter-spacing: -3px;
        max-width: 480px;

        @media (min-width: $screen-xxl) {
          font-size: calc(48 / 1440 * 100vw);
          margin-bottom: calc(24 / 1440 * 100vw);
          letter-spacing: calc(-3 / 1440 * 100vw);
          max-width: calc(480 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          font-size: 32px;
          max-width: 270px;
          margin: 0 auto 8px;
          letter-spacing: -2px;
        }
      }

      p {
        font: 400 16px/1.6 $main-font;
        max-width: 430px;
        margin: 0 0 32px;
        color: rgba($white-color, .8);

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          max-width: calc(430 / 1440 * 100vw);
          margin: 0 0 calc(32 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          margin: 0 auto 32px;
        }
      }

      .buttons-wrap {
        display: flex;
        gap: 8px;

        @media (min-width: $screen-xxl) {
          gap: calc(8 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          flex-direction: column;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          padding: 8px;
          border: 1px solid rgba(242, 242, 242, 0.4);
          border-radius: 90px;

          @media (min-width: $screen-xxl) {
            padding: calc(8 / 1440 * 100vw);
            border-width: calc(1 / 1440 * 100vw);
            border-radius: calc(90 / 1440 * 100vw);
          }

          &:hover {

            .text {
              color: $white-color;
            }
          }

          .text {
            font: 500 14px/1.2 $main-font;
            color: rgba($white-color, .8);
            text-decoration: none;
            margin: 0 12px 0 16px;

            @media (min-width: $screen-xxl) {
              font-size: calc(14 / 1440 * 100vw);
              margin: 0 calc(12 / 1440 * 100vw) 0 calc(16 / 1440 * 100vw);
            }
          }

          .icon img {
            width: 40px;

            @media (min-width: $screen-xxl) {
              width: calc(40 / 1440 * 100vw);
            }
          }
        }
      }
    }

    .img-wrap {
      position: absolute;
      bottom: -60px;
      right: 0;
      z-index: 1;
      max-width: 865px;

      @media (min-width: $screen-xxl) {
        bottom: calc(-60 / 1440 * 100vw);
        max-width: calc(865 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        right: -200px;
      }

      @media (max-width: $screen-lg) {
        right: -270px;
      }

      @media (max-width: $screen-md) {
        width: 670px;
        right: -100px;
        bottom: -20px;
      }

      img {
        width: 100%;
      }
    }
  }
}
