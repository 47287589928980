header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba($black-color, .75);

  &.scroll {
    transition: none;
  }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    @media (min-width: $screen-xxl) {
      max-width: 100%;
      padding: calc(20 / 1440 * 100vw);
    }

    @media (max-width: $screen-xl) {
      padding: 32px 40px;
    }

    @media (max-width: $screen-lg) {
      padding: 16px 10px;
    }

    .navbar-brand {
      padding: 0;
      position: relative;
      z-index: 19;
      margin-right: 72px;

      @media (min-width: $screen-xxl) {
        margin-right: calc(72 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        margin-right: 40px;
      }

      @media (max-width: $screen-lg) {
        z-index: 22;
      }

      img {

        @media (min-width: $screen-xxl) {
          width: calc(56 / 1440 * 100vw);
        }
      }
    }

    .navbar-buttons {
      display: flex;
      margin-left: auto;
      max-width: max-content;
      gap: 8px;

      @media (max-width: $screen-lg) {
        margin-left: 0;
      }

      &.centered {

        @media (max-width: $screen-lg) {
          margin: 0 auto;
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 8px;
        border: 1px solid rgba(242, 242, 242, 0.4);
        border-radius: 90px;

        @media (min-width: $screen-xxl) {
          padding: calc(8 / 1440 * 100vw);
          border: calc(1 / 1440 * 100vw) solid rgba(242, 242, 242, 0.4);
          border-radius: calc(90 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          border: 0;
          padding: 0;
        }

        &:hover {

          .text {
            color: $white-color;
          }
        }

        .text {
          font: 500 14px/1.2 $main-font;
          color: rgba($white-color, .8);
          text-decoration: none;
          margin: 0 12px 0 16px;

          @media (min-width: $screen-xxl) {
            font-size: calc(14 / 1440 * 100vw);
            margin: 0 calc(12 / 1440 * 100vw) 0 calc(16 / 1440 * 100vw);
          }
        }

        .icon img {
          width: 40px;

          @media (min-width: $screen-xxl) {
            width: calc(40 / 1440 * 100vw);
          }
        }
      }
    }

    .navbar-collapse {
      flex-grow: 1;

      @media (max-width: $screen-lg) {
        display: none;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        z-index: 21;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--app-height);
        overflow-y: auto;
        background-color: $black-color;
      }

      &.show {
        display: flex;
        transform: none;
      }

      .navbar-nav {
        flex: 1;

        @media (max-width: $screen-lg) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 150px 40px 100px;
          width: 100%;
        }

        @media (max-width: $screen-lg) {
          padding: 150px 30px 100px;
        }

        .nav-item {
          display: flex;
          align-items: center;
          margin: 0 32px 0 0;

          @media (min-width: $screen-xxl) {
            margin: 0 calc(32 / 1440 * 100vw) 0 0;
          }

          &:last-child {
            margin: 0;
          }

          @media (max-width: $screen-xl) {
            margin: 0 24px 0 0;
          }

          @media (max-width: $screen-lg) {
            margin: 0 0 32px;
          }

          &:last-child {
            @media (max-width: $screen-lg) {
              margin: 0;
            }
          }

          .nav-link {
            font: 500 16px/1.2 $main-font;
            color: rgba($white-color, .7);
            padding: 0;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
            }

            @media (max-width: $screen-lg) {
              font-size: 24px;
              color: $white-color;
            }

            &:hover, &.active {
              opacity: 1;
            }

            &.active, &.disabled {
              pointer-events: none;
            }
          }
        }
      }

      .navbar-links {
        display: flex;
        gap: 11px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        @media (min-width: $screen-xxl) {
          gap: calc(11 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          margin: auto auto 32px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          border-radius: 40px;
          background: linear-gradient(27deg, #9750F1 0%, #3677EE 100%);

          @media (min-width: $screen-xxl) {
            height: calc(40 / 1440 * 100vw);
            width: calc(40 / 1440 * 100vw);
            border-radius: calc(40 / 1440 * 100vw);
          }

          img {
            width: 100%;
            max-width: 24px;

            @media (min-width: $screen-xxl) {
              max-width: calc(24 / 1440 * 100vw);
            }
          }
        }
      }
    }

    .navbar-toggler {
      padding: 0;
      border: 0;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      position: relative;
      background: linear-gradient(27deg, #9750F1 0%, #3677EE 100%);

      @media (max-width: $screen-lg) {
        position: relative;
        width: 54px;
        height: 54px;
        border-radius: 54px;
        z-index: 22;
      }

      &.collapsed {

        span.icon-bar {
          background-color: $white-color;
        }
      }

      &:not(.collapsed) {

        .icon-bar {

          &:nth-child(1) {
            transform: translateY(5px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translateX(0);
          }

          &:nth-child(3) {
            transform: translateY(-5px) rotate(-45deg);
          }
        }
      }

      span.bg {
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        z-index: 2;
        //background-color: #111635;
        //border-radius: 14px;
      }

      span.icon-bar {
        position: relative;
        z-index: 3;
        display: block;
        width: 16px;
        height: 1px;
        margin: 0 auto 4px;
        background-color: $white-color;
        transition: opacity, transform;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);

        &:nth-child(3) {
          margin: 0 auto;
        }
      }
    }
  }
}
