@import url('https://fonts.googleapis.com/css2?family=Carattere&family=Inter:wght@300;400;500;600;700&display=swap');

// Fonts
$main-font: 'Inter', sans-serif;
$headline-font: 'Carattere', cursive;

body {

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
}
