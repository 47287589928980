.countdown-modal-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba($black-color, .8);
  backdrop-filter: blur(10px);
  transition: none;
  padding: 24px;

  @media (min-width: $screen-xxl) {
    backdrop-filter: blur(calc(10 / 1440 * 100vw));
  }

  @media (max-width: $screen-xl) {
    min-height: var(--app-height);
  }

  * {
    transition: none;
  }

  .modal-wrap {
    padding: 80px;
    max-width: max-content;
    text-align: center;
    background: rgba(4, 8, 17, .64);
    border: 1px solid rgba($white-color, .2);
    backdrop-filter: blur(12px);
    border-radius: 16px;

    @media (min-width: $screen-xxl) {
      padding: calc(80 / 1440 * 100vw);
      backdrop-filter: blur(calc(12 / 1440 * 100vw));
      border-radius: calc(16 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      padding: 24px 8px;
    }

    h3 {
      font: 500 48px/.9 $main-font;
      color: $white-color;
      margin-bottom: 32px;
      letter-spacing: -5px;

      @media (min-width: $screen-xxl) {
        font-size: calc(48 / 1440 * 100vw);
        margin-bottom: calc(32 / 1440 * 100vw);
        letter-spacing: calc(-5 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        font-size: 32px;
        letter-spacing: -2px;
        max-width: 200px;
        margin: 0 auto 16px;
      }
    }

    p {
      font: 400 16px/1.6 $main-font;
      max-width: 510px;
      margin: 0 auto 32px;
      color: rgba($white-color, .8);
      letter-spacing: calc(-16px / 100 * 4);

      @media (min-width: $screen-xxl) {
        font-size: calc(16 / 1440 * 100vw);
        margin: 0 auto calc(32 / 1440 * 100vw);
        max-width: calc(510 / 1440 * 100vw);
        letter-spacing: calc((-16 / 1440 / 100 * 4) * 100vw);
      }

      @media (max-width: $screen-xl) {
        margin: 0 auto 40px;
      }

      @media (max-width: $screen-md) {
        font-size: 14px;
        max-width: 300px;
        margin: 0 auto 32px;
      }
    }

    .buttons-wrap {
      display: flex;
      justify-content: center;
      gap: 8px;

      @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: center;
      }

      .btn-main {
        font-size: 16px;
        height: 60px;
        padding: 24px 48px;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          padding: calc(24 / 1440 * 100vw) calc(48 / 1440 * 100vw);
          height: calc(60 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          width: 100%;
          max-width: 260px;
          padding: 24px 32px;
        }
      }
    }
  }
}
