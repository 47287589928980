.btn-main {
  font: 500 20px/1.2px $main-font;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  position: relative;
  max-width: max-content;
  color: $white-color;
  padding: 20px 54px;
  height: 72px;
  border-radius: 90px;
  border: 0;
  background: transparent;

  @media (min-width: $screen-xxl) {
    font-size: calc(20 / 1440 * 100vw);
    padding: calc(20 / 1440 * 100vw) calc(54 / 1440 * 100vw);
    height: calc(72 / 1440 * 100vw);
    border-radius: calc(90 / 1440 * 100vw);
  }

  @media (max-width: $screen-md) {
    font-size: 14px;
    height: 56px;
    padding: 12px 40px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(27deg, #9750F1 0%, #3677EE 100%);
    box-shadow: 0 4px 10px 0 rgba($white-color, .6) inset;
    border-radius: 80px;
    z-index: 1;

    @media (min-width: $screen-xxl) {
      border-radius: calc(80 / 1440 * 100vw);
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }

  &:hover {
    color: $white-color;
    background-color: rgba($white-color, .6);

    .bg {
      background: linear-gradient(27deg, #3677EE 0%, #9750F1 100%);
    }
  }

  &.btn-gray {
    border: 1px solid $white-color;
    background-color: transparent;

    @media (min-width: $screen-xxl) {
      border: calc(1 / 1440 * 100vw) solid $white-color;
    }

    .bg {
      background: rgba($white-color, .2);
      backdrop-filter: blur(10px);
    }

    &:hover {
      border-color: rgba($white-color, .8);

      .bg {
        background: rgba($white-color, .25);
      }
    }
  }
}

.btn-disabled {
  opacity: .7;
  touch-action: none;
  pointer-events: none;
}
