.main-experience-section {
  min-height: 810px;
  padding: 330px 0 48px;

  @media (min-width: $screen-xxl) {
    min-height: calc(810 / 1440 * 100vw);
    padding: calc(330 / 1440 * 100vw) 0 calc(48 / 1440 * 100vw);
  }

  @media (max-width: $screen-lg) {
    margin: 140px 0 120px;
    padding: 0;
    min-height: max-content;
  }

  @media (max-width: $screen-md) {
    margin: 80px 0 100px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $screen-lg) {
      position: relative;
    }

    > div {
      padding: 0;
      margin: 0;
      z-index: 1;

      &.img-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;

        img {
          height: 90%;

          @media (max-width: $screen-md) {
            height: auto;
            width: 100%;
          }
        }
      }

      &.headline-wrap {
        display: flex;
        max-width: max-content;
        margin: 0 auto 70px;
        text-align: center;
        gap: 16px;

        @media (min-width: $screen-xxl) {
          margin: 0 auto calc(70 / 1440 * 100vw);
          gap: calc(16 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          margin: 200px 0 54px;
        }

        @media (max-width: $screen-md) {
          margin: 0 auto;
          flex-direction: column;
          gap: 8px;
        }

        h2 {
          font: 500 88px/.9 $main-font;
          color: $white-color;
          letter-spacing: -9px;

          @media (min-width: $screen-xxl) {
            font-size: calc(88 / 1440 * 100vw);
            letter-spacing: calc(-9 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            font-size: 72px;
            letter-spacing: -6px;
          }

          @media (max-width: $screen-md) {
            font-size: 48px;
            letter-spacing: -3px;
          }
        }
      }

      &.play-wrap {
        max-width: max-content;
        cursor: pointer;
        margin: 0 auto 100px;

        @media (min-width: $screen-xxl) {
          margin: 0 auto calc(100 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          margin: 0 auto 54px;
        }

        @media (max-width: $screen-md) {
          margin: 40px auto;
        }

        img {
          width: 96px;

          @media (min-width: $screen-xxl) {
            width: calc(96 / 1440 * 100vw);
          }
        }
      }

      &.text-wrap {
        font: 400 16px/1.6 $main-font;
        color: #D7D7D7;
        max-width: 300px;
        text-align: center;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          max-width: calc(300 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          margin-bottom: 50px;
        }

        @media (max-width: $screen-md) {
          margin-bottom: 0;
        }
      }
    }
  }
}
